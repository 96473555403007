/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import './src/css/index.css';
import './src/css/webfonts.css';
import { preserveUtm } from './src/helpers/utm-utils'

// Logs when the initial (but not subsequent) render of Gatsby App is done on the client
export const onClientEntry = () => {
  if (window.location.search.indexOf('utm') > -1) {
    preserveUtm(window.location.search);
  }
}
