// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-convenience-retail-engage-tsx": () => import("./../../../src/pages/convenience-retail/engage.tsx" /* webpackChunkName: "component---src-pages-convenience-retail-engage-tsx" */),
  "component---src-pages-convenience-retail-index-tsx": () => import("./../../../src/pages/convenience-retail/index.tsx" /* webpackChunkName: "component---src-pages-convenience-retail-index-tsx" */),
  "component---src-pages-convenience-retail-scan-data-tsx": () => import("./../../../src/pages/convenience-retail/scan-data.tsx" /* webpackChunkName: "component---src-pages-convenience-retail-scan-data-tsx" */),
  "component---src-pages-customer-stories-tsx": () => import("./../../../src/pages/customer-stories.tsx" /* webpackChunkName: "component---src-pages-customer-stories-tsx" */),
  "component---src-pages-downloads-tsx": () => import("./../../../src/pages/downloads.tsx" /* webpackChunkName: "component---src-pages-downloads-tsx" */),
  "component---src-pages-enterprise-engage-tsx": () => import("./../../../src/pages/enterprise/engage.tsx" /* webpackChunkName: "component---src-pages-enterprise-engage-tsx" */),
  "component---src-pages-enterprise-index-tsx": () => import("./../../../src/pages/enterprise/index.tsx" /* webpackChunkName: "component---src-pages-enterprise-index-tsx" */),
  "component---src-pages-enterprise-insights-tsx": () => import("./../../../src/pages/enterprise/insights.tsx" /* webpackChunkName: "component---src-pages-enterprise-insights-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-get-started-tsx": () => import("./../../../src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-log-in-tsx": () => import("./../../../src/pages/log-in.tsx" /* webpackChunkName: "component---src-pages-log-in-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-product-updates-tsx": () => import("./../../../src/pages/product-updates.tsx" /* webpackChunkName: "component---src-pages-product-updates-tsx" */),
  "component---src-pages-referrals-tsx": () => import("./../../../src/pages/referrals.tsx" /* webpackChunkName: "component---src-pages-referrals-tsx" */),
  "component---src-pages-reports-tsx": () => import("./../../../src/pages/reports.tsx" /* webpackChunkName: "component---src-pages-reports-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-videos-tsx": () => import("./../../../src/pages/videos.tsx" /* webpackChunkName: "component---src-pages-videos-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-campaign-tsx": () => import("./../../../src/templates/campaign.tsx" /* webpackChunkName: "component---src-templates-campaign-tsx" */),
  "component---src-templates-customer-story-tsx": () => import("./../../../src/templates/customer-story.tsx" /* webpackChunkName: "component---src-templates-customer-story-tsx" */),
  "component---src-templates-download-tsx": () => import("./../../../src/templates/download.tsx" /* webpackChunkName: "component---src-templates-download-tsx" */),
  "component---src-templates-press-release-tsx": () => import("./../../../src/templates/press-release.tsx" /* webpackChunkName: "component---src-templates-press-release-tsx" */),
  "component---src-templates-promotion-tsx": () => import("./../../../src/templates/promotion.tsx" /* webpackChunkName: "component---src-templates-promotion-tsx" */),
  "component---src-templates-report-tsx": () => import("./../../../src/templates/report.tsx" /* webpackChunkName: "component---src-templates-report-tsx" */),
  "component---src-templates-resources-tsx": () => import("./../../../src/templates/resources.tsx" /* webpackChunkName: "component---src-templates-resources-tsx" */),
  "component---src-templates-video-tsx": () => import("./../../../src/templates/video.tsx" /* webpackChunkName: "component---src-templates-video-tsx" */)
}

